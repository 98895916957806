<app-navigation></app-navigation>
<div class="container">
    <div class="table-responsive text-nowrap">
        <h2 class="mt-3 solvay">Create New Claim Summary Report</h2>
        <div #reportpdf>
            <table class="table">
                <thead class="black white-text">
                    <tr>
                        <th scope="col">Claim ID</th>
                        <th scope="col">Submission Date</th>
                        <th scope="col">Claim Type</th>
                        <th scope="col">Claim For</th>
                        <th scope="col">Total Claim</th>
                        <th scope="col">Claim Status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let claim of reportedClaims">
                        <td>{{this.claimService.formatClaimID(claim.claimId)}}</td>
                        <td>{{this.claimService.formatDate(claim.submissionDate)}}</td>
                        <td>{{claim.typeOfClaim}}</td>
                        <td>{{claim.dependentName}}</td>
                        <td>{{claim.approvedAmount}}</td>
                        <td>{{claim.status}}</td>
                        <td><button type="submit" class="btn redtext mr-2" (click)="removeReport(claim.claimId)">Remove from
                Report</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!--<button type="submit" class="btn btn-danger mr-2" (click)="test()">Test</button>-->
    <button type="submit" class="btn btn-success mr-2" (click)="download()">Generate Report</button>
    <button type="submit" class="btn btn-danger" (click)="clearReport()">Clear Report</button>
    <br />
    <br />
    <h2 class="mt-3 solvay">Available Claims</h2>
    <div class="table-responsive text-nowrap">
        <div class="form-row">
            <div class="form-group col-md-6">
                <label class="solvay">Claim ID:</label>
                <input type="text" class="form-control" [(ngModel)]="claimId" name="claimId" />
            </div>
            <!--<div class="form-group col-md-6">
            <label class="solvay">Claim Type:</label>
            <select class="form-control solvay" [(ngModel)]="typeOfClaim.id"
              (change)="selectedClaimTypeHandler(typeOfClaim.id)">
              <option [ngValue]="undefined" hidden>Select Claim Type</option>
              <option *ngFor="let i of typeOfClaim" [ngValue]="i.id">{{ i.label }}</option>
            </select>
          </div>-->
            <div class="form-group col-md-6">
                <label class="solvay">Status:</label>
                <select class="form-control solvay" [(ngModel)]="status['id']" (change)="selectedStatusHandler(status['id'])">
          <option [ngValue]="undefined" hidden>Select Status</option>
          <option *ngFor="let i of status" [ngValue]="i.id">{{ i.label }}</option>
        </select>
            </div>
        </div>
        <div class="form-row">
            <div class="col-md-6">
                <label class="solvay">Submission Date From:</label>
                <div class="form-group">
                    <div class="input-group">
                        <input class="form-control solvay" readonly="true" placeholder="yyyy-mm-dd" [(ngModel)]="submissionDateFrom" name="submissionDateFrom" ngbDatepicker #d="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                <img src="assets/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;" />
              </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <label class="solvay">Submission Date To:</label>
                <div class="form-group">
                    <div class="input-group">
                        <input class="form-control solvay" readonly="true" placeholder="yyyy-mm-dd" [(ngModel)]="submissionDateTo" name="submissionDateTo" ngbDatepicker #e="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" (click)="e.toggle()" type="button">
                <img src="assets/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;" />
              </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class=form-row>
            &nbsp;&nbsp;
            <button type="submit" class="btn btn-success mr-2" (click)="search()">Search</button>
            <button type="reset" class="btn btn-danger" (click)="clearReport()">Reset</button>
        </div>
        <br /><br />
        <table class="table" matSort (matSortChange)="sortData($event)">
            <thead class="black white-text">
                <tr>
                    <th mat-sort-header="claimId" scope="col">Claim ID</th>
                    <th mat-sort-header="submissionDate" scope="col">Submission Date</th>
                    <th mat-sort-header="typeOfClaim" scope="col">Claim Type</th>
                    <th mat-sort-header="dependentName" scope="col">Claim For</th>
                    <th mat-sort-header="claimAmount" scope="col">Total Claim</th>
                    <th mat-sort-header="status" scope="col">Claim Status</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let claim of sortedData">
                    <td>{{this.claimService.formatClaimID(claim.claimId)}}</td>
                    <td>{{this.claimService.formatDate(claim.submissionDate)}}</td>
                    <td>{{claim.typeOfClaim}}</td>
                    <td>{{claim.dependentName}}</td>
                    <td>{{this.claimService.formatMoney(claim.approvedAmount)}}</td>
                    <td>{{claim.status}}</td>
                    <td><button type="submit" class="btn bluetext mr-2" (click)="addReport(claim.claimId)">Add to Report</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<app-footer></app-footer>