<app-navigation></app-navigation>
<br />
<div class="container">
    <br />
    <div class="col-sm-12">
        <h2 class="solvay">
            Claim Details
        </h2>
    </div>
    <br />
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">Claim ID</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimService.formatClaimID(this.claimId)}}</label>
        </div>
    </div>
    <div class="row even">
        <div class="col-md-3">
            <label class="solvay">Claim For</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimDetail.dependentName}}</label>
        </div>
    </div>
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">Claim Type</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimDetail.typeOfClaim}}</label>
        </div>
    </div>
    <div class="row even">
        <div class="col-md-3">
            <label class="solvay">Claim Category</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimDetail.categoryOfClaim}}</label>
        </div>
    </div>
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">Receipt Number</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimDetail.receiptNo}}</label>
        </div>
    </div>
    <div class="row even">
        <div class="col-md-3">
            <label class="solvay">Receipt Date</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimService.formatDate(this.claimDetail.receiptDate)}}</label>
        </div>
    </div>
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">Claim Amount</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimService.formatMoney(this.claimDetail.claimAmount)}}</label>
        </div>
    </div>
    <div class="row even">
        <div class="col-md-3">
            <label class="solvay">Employee Notes</label>
        </div>
        <div class="col-md-6" *ngIf="this.status == 'Pending Attachment' || this.status == 'Submitted'">
            <textarea class="solvay" name="employeeNotes" id="employeeNotes" cols="33" rows="4" [(ngModel)]="employeeNotes"></textarea>
        </div>
        <div class="col-md-6" *ngIf="this.status != 'Pending Attachment' && this.status != 'Submitted'">
            <label class="solvay">{{this.claimDetail.employeeNotes}}</label>
        </div>
    </div>
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">Approved Claim Amount</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimService.formatMoney(this.claimDetail.approvedAmount)}}</label>
        </div>
    </div>
    <div class="row even">
        <div class="col-md-3">
            <label class="solvay">Employee ID</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimDetail.employeeId}}</label>
        </div>
    </div>
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">Claim By</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimDetail.employeeName}}</label>
        </div>
    </div>
    <div class="row even">
        <div class="col-md-3">
            <label class="solvay">Submission Date</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimService.formatDate(this.claimDetail.submissionDate)}}</label>
        </div>
    </div>
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">Last Update Date</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimService.formatDate(this.claimDetail.updatedOn)}}</label>
        </div>
    </div>
    <div class="row even">
        <div class="col-md-3">
            <label class="solvay">Status</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.status}}</label>
        </div>
    </div>
    <div class="row odd" *ngIf="this.status != 'Pending Attachment'">
        <div class="col-md-3">
            <label class="solvay">HR Notes</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimDetail.notes}}</label>
        </div>
    </div>
    <div class="row even" *ngIf="this.chkConsent == 'true'">
        <div class="col-md-3">
            <label class="solvay">Consent</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">Yes, </label><br/><span>I consent to the processing of my health information and any other sensitive data as shown in the submitted medical certificate.<br/>In case of submission of my family’s medical certificate, I hereby confirm that I have duly received the consent of the respective person and is fully authorized to submit the medical certificate to Solvay on their behalf.
					</span>
        </div>
    </div>
    <div class="row">&nbsp; </div>
    <app-file-upload></app-file-upload>
    <div class="row">&nbsp; </div>
    <button *ngIf="this.status === 'Submitted' || this.status === 'Pending Attachment'" type="submit" class="btn btn-primary mr-2" (click)="cancelClaim()">Cancel This Claim</button>
    <button *ngIf="this.status === 'Pending Attachment'" type="submit" class="btn btn-success mr-2" (click)="submitClaim('Submitted')">Submit Claim</button>
    <button type="button" class="btn btn-danger" (click)="cancel()">Back</button>
</div>
<app-footer></app-footer>