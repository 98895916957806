import { Pipe, PipeTransform } from '@angular/core';
import { Claim } from './claim';

@Pipe({
  name: 'claimSearch'
})

export class ClaimSearchPipe implements PipeTransform {
  date: Date;
    transform(items: Claim[], claimIdSearch: string, claimTypeSearch: string, claimDateFrom: Date, claimDateTo: Date, claimStatusSearch: string, employeeIdSearch: string, receiptNoSearch: string, dependentNameSearch: string) {
      if (items && items.length) {
        return items.filter(item => {
          if (claimIdSearch && item.claimId.toString().indexOf(claimIdSearch) === -1) {
            return false;
          }
          if (claimTypeSearch && item.typeOfClaim.indexOf(claimTypeSearch) === -1) {
            return false;
          }
          if (claimStatusSearch && item.status.indexOf(claimStatusSearch) === -1) {
            return false;
          }
          if (claimDateFrom && (new Date(item.submissionDate) < claimDateFrom)){
            return false;
          }
          if (claimDateTo && (new Date(item.submissionDate) >= new Date((new Date(claimDateTo)).getTime() + (60*60*24*1000)))){
            return false;
          }
          if (employeeIdSearch && item.employeeId.toLowerCase().indexOf(employeeIdSearch.toLowerCase()) === -1) {
            return false;
          }
          if (receiptNoSearch && item.receiptNo.toLowerCase().indexOf(receiptNoSearch.toLowerCase()) === -1) {
            return false;
          }
          if (dependentNameSearch && item.dependentName.toLowerCase().indexOf(dependentNameSearch.toLowerCase()) === -1) {
            return false;
          }
          return true;
        })
      }
      else {
        return items;
      }
    }
  }
