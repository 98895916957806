import { Injectable } from '@angular/core';

@Injectable()
export class LoadingService {
    public loading: boolean;

    constructor() {
        this.enableLoading();
    }

    enableLoading(): void {
        this.loading = true;
    };

    disableLoading(): void {
        this.loading = false;
    };
}
