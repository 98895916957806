import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserService } from '../loginCore/user.service';

@Injectable()
export class AuthGuard  {

  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router
  ) {}

  canActivate(): Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser()
      .then(user => {
        console.log('Guard works. User login');
        return resolve(true);
      }, err => {
        console.log('Guard works. User not login');
        this.router.navigate(['/login']);
        return resolve(false);
      })
    })
  }

}
