<div *ngIf="this.claimDetail.status === 'Pending Attachment' && (!isHRRole() || this.userService.getHRMenu() === 'emp')" class="container">
    <div class="card">
        <div class="card-header solvay">
            Attach your receipt
        </div>
        <div class="card-body">
            <h5 class="card-title solvay">Select a file for upload:</h5>
            <input #myInput class="solvay" type="file" (change)="upload($event)" accept=".png,.jpg,.jpeg,.pdf" />
        </div>
        <div class="progress">
            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadProgress | async) + '%'" [attr.aria-valuenow]="(uploadProgress | async)" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div *ngIf="uploadProgress | async; let downloadSrc" class="alert alert-info solvay" role="alert">
            File uploaded!
        </div>
        <div class="row">&nbsp; </div>

        <ul class="solvay">
            <div *ngFor="let attachment of this.claimDetail.attachments; let i = index">
                <li *ngIf="attachment.deleted === false">
                    <div class="row">
                        <div class="col-md-2">
                            <span class="badge"><a href={{attachment.googleFileId}} target="_blank">{{attachment.claimId}}-{{i+1}}</a></span>
                        </div>
                        <div class="col-md-2">
                            <a href="javascript:void(0);" (click)="delete(attachment.keyString)"><img src="assets/delete.jpg" style="width:15px; height:15px;"></a>
                        </div>
                    </div>
                </li>
            </div>
        </ul>
    </div>
</div>
<div *ngIf="this.claimDetail.status !== 'Pending Attachment' || (isHRRole() && this.userService.getHRMenu() === 'hr')" class="container">
    <div class="card">
        <div class="card-header solvay">
            Your attached receipt(s)
        </div>
        <div class="row">&nbsp; </div>
        <div class="row">
            <ul class="solvay">
                <li *ngFor="let attachment of this.claimDetail.attachments; let i = index">
                    <span class="badge"><a href="javascript:void(0);" (click)="getAttachment(attachment.keyString)">{{attachment.claimId}}-{{i+1}}</a></span>
                </li>
            </ul>
        </div>
    </div>
</div>