<div class="container">
  <div class="jumbotron mt-md-5 col-sm-7 auto">
    <div class="form-row">
      <div class="auto">
        <a routerLink="/webform"><img class="solvayLogo" src="assets\solvay.png" alt="Solvay"/></a>
      </div>
    </div>
    <div class="form-row">
      <div class="auto">
        <h2 class="solvay">
          iFlex - Thailand
        </h2>
      </div>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <div class="form-row">
      <div class="auto">
        <button type="button" class="btn btn-primary solvay" id="loginBtn" (click)="tryGoogleLogin()">Login with Solvay Account</button>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
