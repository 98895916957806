import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { SubmitClaimComponent } from './submit-claim/submit-claim.component';
import { AuthGuard } from './loginCore/auth.guard';
import { EmployeeClaimreportComponent } from './employee-claimreport/employee-claimreport.component';
import { HrViewclaimComponent } from './hr-viewclaim/hr-viewclaim.component';
import { HrClaimdetailComponent } from './hr-claimdetail/hr-claimdetail.component';
import { EmployeeViewclaimComponent } from './employee-viewclaim/employee-viewclaim.component';
import { EmployeeClaimdetailComponent } from './employee-claimdetail/employee-claimdetail.component';
import { HrSummaryreportComponent } from './hr-summaryreport/hr-summaryreport.component';
import { EmployeeViewquotaComponent } from './employee-viewquota/employee-viewquota.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { AttachmentComponent } from './attachment/attachment.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: {breadcrumb: 'home'} },
  { path: 'employeeViewQuota', component: EmployeeViewquotaComponent, canActivate: [AuthGuard]},
  { path: 'submitClaim', component: SubmitClaimComponent, canActivate: [AuthGuard] },
  { path: 'claimReport', component: EmployeeClaimreportComponent, canActivate: [AuthGuard], data: {breadcrumb: 'Employee Claim Report'} },
  { path: 'hrViewClaim', component: HrViewclaimComponent, canActivate: [AuthGuard]},
  { path: 'hrClaimDetail', component: HrClaimdetailComponent, canActivate: [AuthGuard]},
  { path: 'employeeViewClaim', component: EmployeeViewclaimComponent, canActivate: [AuthGuard]},
  { path: 'employeeClaimDetail', component: EmployeeClaimdetailComponent, canActivate: [AuthGuard]},
  { path: 'hrSummaryReport', component: HrSummaryreportComponent, canActivate: [AuthGuard]},
  { path: 'fileUpload', component: FileUploadComponent, canActivate: [AuthGuard]},
  { path: 'attachment', component: AttachmentComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
