<app-navigation></app-navigation>
<br>
<div *ngIf="showContent">
<div class="container">
    <div class="row">
        <div class="col-md-1">
            <label class="solvay">Period:</label></div>
        <div class="col-md-2">
            <select class="custom-select form-control solvay dropdown" name="quotaYear" required [(ngModel)]="quotaYear" (change)="selectedQuotaYearHandler(quotaYear);getFamilyMembers();getFlexiPoint();">
        <option [ngValue]="this.currentYear">{{this.currentYear}}</option>
        <option [ngValue]="this.lastYear" *ngIf="isBackDateClaim">{{this.lastYear}}</option>
      </select>
        </div>
    </div>
    <br/>
    <label class="solvay"><strong>Flexi Amount</strong></label>
    <div class="row">
        <div class="solvay-label col-sm-3"><label class="label-quota">Available Flexi Amount</label></div>
        <div class="col-sm-5"><label class="label-quota">{{ flexiSummary?.availableFlexi | number : '1.2-2' }}</label>
        </div>
    </div>
    <div class="row">
        <div class="solvay-label col-sm-3"><label class="label-quota">Paid Flexi Amount</label></div>
        <div class="col-sm-5"><label class="label-quota">{{ flexiSummary?.paidFlexi | number : '1.2-2' }}</label>
        </div>
    </div>
    <div class="row">
        <div class="solvay-label col-sm-3"><label class="label-quota">In Process Flexi Amount</label></div>
        <div class="col-sm-5"><label class="label-quota">{{ flexiSummary?.pendingFlexi | number : '1.2-2' }}</label>
        </div>
    </div>
</div>
<br />
<div class="container">
    <div class="row">
        <div *ngFor="let family of this.familyMembers">
            <div *ngFor="let dental of this.dentalSummary">
                <div *ngIf="dental.personID == family.personId && (family.relationship != 'Father' && family.relationship != 'Mother')">
                    <div class="container"><label class="solvay"><strong>Dental Amount for
                {{ family.firstName +' '+ family.lastName }} ({{ family.relationship }})</strong></label>
                        <div class="row">
                            <div class="solvay-label col-sm-8"><label class="label-quota">Dental Amount</label></div>
                            <div class="col-sm-3"><label class="label-quota">{{ dental.availableDental | number : '1.2-2' }}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="solvay-label col-sm-8"><label class="label-quota">Paid Dental Amount</label></div>
                            <div class="col-sm-3"><label class="label-quota">{{ dental.paidDental | number : '1.2-2' }}</label></div>
                        </div>
                        <div class="row">
                            <div class="solvay-label col-sm-8"><label class="label-quota">In Process Dental Amount</label></div>
                            <div class="col-sm-3"><label class="label-quota">{{ dental.pendingDental | number : '1.2-2' }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<div *ngIf="showEAP" class="container">
    <label class="solvay"><strong>EAP (Mental Health) Amount</strong></label>
    <div class="row">
        <div class="solvay-label col-sm-4"><label class="label-quota">Available EAP (Mental Health) Amount</label></div>
        <div class="col-sm-5"><label class="label-quota">{{ eapSummary?.availableEAP | number : '1.2-2' }}</label>
        </div>
    </div>
    <div class="row">
        <div class="solvay-label col-sm-4"><label class="label-quota">Paid EAP (Mental Health) Amount</label></div>
        <div class="col-sm-5"><label class="label-quota">{{ eapSummary?.paidEAP | number : '1.2-2' }}</label>
        </div>
    </div>
    <div class="row">
        <div class="solvay-label col-sm-4"><label class="label-quota">In Process EAP (Mental Health) Amount</label></div>
        <div class="col-sm-5"><label class="label-quota">{{ eapSummary?.pendingEAP | number : '1.2-2' }}</label>
        </div>
    </div>
</div>
<br />
</div>
<app-footer></app-footer>
