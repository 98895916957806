<!-- Navigation -->
<ngx-loading [show]="loadingService.loading" [config]="{ backdropBorderRadius:'14px', fullScreenBackdrop: true }"></ngx-loading>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <img src="assets\solvayvertical.png" width="40" height="40" class="d-inline-block align-top" alt="">
    <!--<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>-->
    <!--<div class="collapse navbar-collapse" id="navbarSupportedContent"> -->
    <div class="row container-fluid">
        <div class="col-sm-8">
            <label class="topnav">
        <a href="/home" class="nav-link">Home</a>
        <a href="/employeeViewQuota" class="nav-link">View Quota</a>
        <a href="/employeeViewClaim" class="nav-link">Search for my claim</a>
        <a href="/submitClaim" class="nav-link">Submit a claim</a>
        <a href="/claimReport" class="nav-link">Generate Claim Report</a>
      </label>
            <div ngbDropdown class="d-inline-block vertical-middle">
                <button class="btn btn-outline-primary" id="dropdownBasic1" *ngIf="isHRRole()" ngbDropdownToggle>HR Console</button>
                <div ngbDropdownMenu>
                    <button class="dropdown-item" (click)="viewClaim()">View Claims</button>
                    <button class="dropdown-item" (click)="viewSummaryReport()">Claim Summary Report</button>
                </div>
            </div>
        </div>
        <div class="col-sm-4 right-horizontal">
            <label>Welcome, <b>{{this.userName}}</b>&nbsp;&nbsp;</label>
            <button type="button" class="btn btn-primary solvay" id="logoutBtn" (click)="logout()">Logout</button>
            <label><a href="https://wiki.solvay.com/display/ISAPPSUP/iFlex-Thai" target="_blank" class="nav-link">FAQ</a></label>
        </div>

    </div>
    <!--</div> -->
</nav>