<app-navigation></app-navigation>
<br />
<div class="container">
    <div *ngIf="showFlexi">
        <div class="container">
            <label class="solvay"><strong><label class="{{ isQuotaYearCurrentYear() ? 'current-quota-year' : 'past-quota-year' }}">[{{this.quotaYear}}]</label> Flexi Amount</strong>
            </label>
            <div class="row">
                <div class="solvay-label col-sm-3"><label class="label-quota">Available Flexi Amount</label></div>
                <div class="col-sm-3"><label class="label-quota">{{ flexiSummary?.availableFlexi | number : '1.2-2' }}</label>
                </div>
            </div>
            <div class="row">
                <div class="solvay-label col-sm-3"><label class="label-quota">Paid Flexi Amount</label></div>
                <div class="col-sm-3"><label class="label-quota">{{ flexiSummary?.paidFlexi | number : '1.2-2' }}</label>
                </div>
            </div>
            <div class="row">
                <div class="solvay-label col-sm-3"><label class="label-quota">In Process Flexi Amount</label></div>
                <div class="col-sm-3"><label class="label-quota">{{ flexiSummary?.pendingFlexi | number : '1.2-2' }}</label>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showDental">
        <div class="container">
            <label class="solvay"><strong><label class="{{ isQuotaYearCurrentYear() ? 'current-quota-year' : 'past-quota-year' }}">[{{this.quotaYear}}]</label> Dental Amount</strong>
            </label>
            <div class="row">
                <div class="solvay-label col-sm-3"><label class="label-quota">Available Dental Amount</label></div>
                <div class="col-sm-3"><label class="label-quota">{{ dentalSummary?.availableDental | number : '1.2-2' }}</label></div>
            </div>
            <div class="row">
                <div class="solvay-label col-sm-3"><label class="label-quota">Paid Dental Amount</label></div>
                <div class="col-sm-3"><label class="label-quota">{{ dentalSummary?.paidDental | number : '1.2-2' }}</label></div>
            </div>
            <div class="row">
                <div class="solvay-label col-sm-3"><label class="label-quota">In Process Dental Amount</label></div>
                <div class="col-sm-3"><label class="label-quota">{{ dentalSummary?.pendingDental | number : '1.2-2' }}</label></div>
            </div>
        </div>
    </div>

    <div *ngIf="showEAP">
        <div class="container">
            <label class="solvay"><strong><label class="{{ isQuotaYearCurrentYear() ? 'current-quota-year' : 'past-quota-year' }}">[{{this.quotaYear}}]</label> EAP (Mental Health) Amount</strong>
            </label>
            <div class="row">
                <div class="solvay-label col-sm-4"><label class="label-quota">Available EAP (Mental Health) Amount</label></div>
                <div class="col-sm-3"><label class="label-quota">{{ eapSummary?.availableEAP | number : '1.2-2' }}</label></div>
            </div>
            <div class="row">
                <div class="solvay-label col-sm-4"><label class="label-quota">Paid EAP (Mental Health) Amount</label></div>
                <div class="col-sm-3"><label class="label-quota">{{ eapSummary?.paidEAP | number : '1.2-2' }}</label></div>
            </div>
            <div class="row">
                <div class="solvay-label col-sm-4"><label class="label-quota">In Process EAP (Mental Health) Amount</label></div>
                <div class="col-sm-3"><label class="label-quota">{{ eapSummary?.pendingEAP | number : '1.2-2' }}</label></div>
            </div>
        </div>
    </div>

    <br />

    <div class="col-sm-12">
        <h2 class="solvay">
            Claim Details
        </h2>
    </div>
    <br />
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">Claim ID</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimService.formatClaimID(this.claimDetail.claimId)}}</label>
        </div>
    </div>
    <div class="row even">
        <div class="col-md-3">
            <label class="solvay">Claim For</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimDetail.dependentName}}</label>
        </div>
    </div>
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">Claim Type</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimDetail.typeOfClaim}}</label>
        </div>
    </div>
    <div class="row even">
        <div class="col-md-3">
            <label class="solvay">Claim Category</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimDetail.categoryOfClaim}}</label>
        </div>
    </div>
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">Receipt Number</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimDetail.receiptNo}}</label>
        </div>
    </div>
    <div class="row even">
        <div class="col-md-3">
            <label class="solvay">Receipt Date</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimService.formatDate(this.claimDetail.receiptDate)}}</label>
        </div>
    </div>
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">Claim Amount</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimService.formatMoney(this.claimDetail.claimAmount)}}</label>
        </div>
        <div *ngIf="(this.claimDetail.typeOfClaim === 'Flexible') && (this.claimAmount > this.availableAmount)" class="col-md-12">
            <label class="solvay redfont">Note: The amount claimed is bigger than the remaining Flexi balance. The
                approved amount will be based on the available remaining balance.</label>
        </div>
        <div *ngIf="(this.claimDetail.typeOfClaim === 'Dental') && (this.claimAmount > this.availableAmount)" class="col-md-12">
            <label class="solvay redfont">Note: The amount claimed is bigger than the remaining Dental balance. The
                approved amount will be based on the available remaining balance.</label>
        </div>
        <div *ngIf="(this.claimDetail.typeOfClaim === 'EAP (Mental Health)') && (this.claimAmount > this.availableAmount)" class="col-md-12">
            <label class="solvay redfont">Note: The amount claimed is bigger than the remaining EAP (Mental Health) balance. The
                approved amount will be based on the available remaining balance.</label>
        </div>
    </div>
    <div class="row even">
        <div class="col-md-3">
            <label class="solvay">Employee Notes</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimDetail.employeeNotes}}</label>
        </div>
    </div>
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">Approved Claim Amount</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimService.formatMoney(this.claimDetail.approvedAmount)}}</label>
        </div>
    </div>
    <div class="row even">
        <div class="col-md-3">
            <label class="solvay">Employee ID</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimDetail.employeeId}}</label>
        </div>
    </div>
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">Claim By</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimDetail.employeeName}}</label>
        </div>
    </div>
    <div class="row even">
        <div class="col-md-3">
            <label class="solvay">Submission Date</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimService.formatDate(this.claimDetail.submissionDate)}}</label>
        </div>
    </div>
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">Last Update Date</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">{{this.claimService.formatDate(this.claimDetail.updatedOn)}}</label>
        </div>
    </div>
    <div class="row even">
        <div class="col-md-3">
            <label class="solvay">Status</label>
        </div>
        <div class="col-md-3">
            <select class="form-control solvay" [(ngModel)]="status['id']" (change)="selectedStatusHandler(status['id'])">
                <option [ngValue]="undefined" hidden>{{ this.selectedStatus }}</option>
                <option *ngFor="let i of status" [ngValue]="i.id">{{ i.label }}</option>
            </select>
        </div>
    </div>
    <div class="row odd">
        <div class="col-md-3">
            <label class="solvay">HR Notes</label>
        </div>
        <div class="col-md-6">
            <textarea class="solvay" cols="33" rows="4" [(ngModel)]="notes"></textarea>
        </div>
    </div>
    <div class="row even" *ngIf="this.chkConsent == 'true'">
        <div class="col-md-3">
            <label class="solvay">Employee Consent</label>
        </div>
        <div class="col-md-6">
            <label class="solvay">Yes, </label><br/><span>I consent to the processing of my health information and any other sensitive data as shown in the submitted medical certificate.<br/>In case of submission of my family’s medical certificate, I hereby confirm that I have duly received the consent of the respective person and is fully authorized to submit the medical certificate to Solvay on their behalf.
					</span>
        </div>
    </div>
    <div class="row">&nbsp;</div>
    <app-file-upload></app-file-upload>
    <div class="row">&nbsp; </div>
    <button type="submit" class="btn btn-success mr-2" (click)="updateClaim()">Save</button>
    <button type="button" class="btn btn-danger" (click)="cancel()">Back</button>
</div>
<app-footer></app-footer>