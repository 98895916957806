import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import { UserService } from '../loginCore/user.service';
import { Person } from '../person';
import { FlexiSummary } from '../flexisummary';
import { DentalSummary } from '../dentalsummary';
import { EAPSummary } from '../eapsummary';
import { EAPBalance } from '../eapBalance';
import { LoadingService } from '../loading.service';
import { UtilService } from '../util.service';
import { Claim } from '../claim';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employee-viewquota',
  templateUrl: './employee-viewquota.component.html',
  styleUrls: ['./employee-viewquota.component.css']
})
export class EmployeeViewquotaComponent implements OnInit {

  constructor(
    private claimService: ClaimService,
    public userService: UserService,
    private loadingService: LoadingService,
    public utilService: UtilService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getQuotaYears();
    this.getInitialEAP();
    this.getFlexiPoint();
    this.getFamilyMembers();
    this.getEAPBalance();
    this.getUserName();
    this.checkBackdateClaim();
    // purposely add delay 3 seconds to avoid display EAP to user
    setTimeout(()=>this.showContent=true, 3000);
  }

  isBackDateClaim: boolean = false;

  familyMembers: Person[];

  submitted = false;

  user: Object = new Object;
  //function to get username from user service
  getUserName(): void {
    this.userService.getCurrentUser().then(user => this.user = user);
  }

  flexiSummary: FlexiSummary;
  dentalSummary: DentalSummary[] = [];
  eapSummary: EAPSummary;
  eapBalance: EAPBalance;
  systemMessage: string;

  families = [
    { id: "father", value: "Father" },
    { id: "mother", value: "Mother" },
    { id: "self", value: "Self" },
    { id: "spouse", value: "Spouse" },
    { id: "child", value: "Child" }
  ];

  flexiType: string = "Flexible";
  dentalType: string = "Dental";
  showFlexi: boolean = false;
  showDental: boolean = false;
  showEAP: boolean = true;
  showContent: boolean = false;

  quotaYear: string;
  currentYear: string;
  lastYear: string;

  disableLoading: number = 0;

  claimTypeList = [
    { id: 1, label: "Flexible" },
    { id: 2, label: "Dental" }
  ];

  // for re-generate claim type based on relatopship
  claimType = [
    { id: 1, label: "Flexible" },
    { id: 2, label: "Dental" }
  ];

  claimCategories = [
    { id: 1, label: "OPD", type: "Flexible" },
    { id: 2, label: "Dental", type: "Flexible" },
    { id: 3, label: "Optical Care", type: "Flexible" },
    { id: 4, label: "Dental", type: "Dental" }
  ];

  public filteredCategories = [];

  selectedClaimType: string;
  selectedCategoryType: string;
  selectedFamilyMember: string;
  selectedPersonName: string;
  selectedPersonRelationship: string;


  selectedQuotaYearHandler(event: any) {
    this.quotaYear = event;
  }

  // get InitialEAPBalance
  getInitialEAP() {
    this.claimService.getInitialEAP(this.quotaYear)
      .subscribe(eapBalance => {
        this.eapBalance = eapBalance;
        if (this.eapBalance["initialEAP"] == 0) {
          this.showEAP = false;
        }
        this.disableLoading += 1;
        if (this.disableLoading == 4) this.loadingService.disableLoading();
      });
  }

  selectedFamilyMembersHandler(event: any) {
    // expect to return selected id
    //this.selectedFamilyMembers = this.familyMembers[event-1].personId;
    this.selectedFamilyMember = event;
    this.getSelectedPersonName(event);
  }

  getSelectedPersonName(personID: string) {
    for (let member of this.familyMembers) {
      if (member.personId == personID) {
        this.selectedPersonName = member.firstName + " " + member.lastName;
      }
    }
  }

  getFamilyMembers() {
    this.claimService.getFamilyMembers()
      .subscribe(familyMembers => {
        this.familyMembers = familyMembers;
        this.familyMembers.sort((a, b) => (a.relationship === "Self") ? -1 : (a.firstName > b.firstName) ? 1 : -1);
        this.dentalSummary = [];
        for (var i = 0; i < familyMembers.length; i++) {
          this.claimService.getDentalSummary(familyMembers[i].personId, this.quotaYear)
            .subscribe(dentalSummary => {
              this.dentalSummary.push(dentalSummary);
            });
        }
        //this.consolidateInfo(this.familyMembers, this.dentalSummary);
        this.disableLoading += 1;
        if (this.disableLoading == 4) this.loadingService.disableLoading();
      });
  }

  getQuotaYears() {
    var quotaYears = this.utilService.getQuotaYears();
    this.currentYear = "" + quotaYears[0];
    this.lastYear = "" + quotaYears[1];
    this.quotaYear = this.currentYear;
  }

  checkBackdateClaim() {
    var thisYear = new Date().getFullYear();
    var date1 = new Date(thisYear + '-01-01');
    var date2 = new Date(thisYear + '-01-31');
    var date3 = new Date();
    if (date3 <= date2 && date3 >= date1) {
      //call the API here, if the the date is lower than the API, then true, else false
      this.claimService.getClosingDate()
        .subscribe(closingDate => {
          if (date3 < new Date(closingDate.closingDate)) {
            this.isBackDateClaim = true;
          }
        });
    }
  }

  getFlexiPoint() {
    this.claimService.getFlexiPoint(this.quotaYear)
      .subscribe(flexiSummary => {
        this.flexiSummary = flexiSummary;
        this.disableLoading += 1;
        if (this.disableLoading == 4) this.loadingService.disableLoading();
      });
  }

  getEAPBalance() {
    this.claimService.getEAPBalance(this.quotaYear)
      .subscribe(eapSummary => {
        this.eapSummary = eapSummary;
        this.disableLoading += 1;
        if (this.disableLoading == 4) this.loadingService.disableLoading();
      });
  }

}
