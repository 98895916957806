import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import { Router } from '@angular/router';
import { UtilService } from '../util.service';
import { LoadingService } from '../loading.service';
import { UserService } from '../loginCore/user.service';
import { Claim } from '../claim';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-employee-claimdetail',
  templateUrl: './employee-claimdetail.component.html',
  styleUrls: ['./employee-claimdetail.component.css']
})
export class EmployeeClaimdetailComponent implements OnInit {
  claimId: string;
  notes: string;
  employeeNotes: string;
  chkConsent: string;

  claim: object = [];
  status: string;

  claimDetail: Claim;
  claimAmount: number;
  availableAmount: number;

  systemMessage: string;

  constructor(
    public claimService: ClaimService,
    public userService: UserService,
    private loadingService: LoadingService,
    private router: Router,
    private utilService: UtilService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    if (this.claimService.getClaimInformation() === undefined) {
      this.claimDetail = JSON.parse(localStorage.getItem('claimObj'));
      this.notes = this.claimDetail.notes;
      this.employeeNotes = this.claimDetail.employeeNotes;
      this.chkConsent = this.claimDetail.chkConsent;
    } else {
      this.claimDetail = this.claimService.getClaimInformation();
      localStorage.setItem('claimObj', JSON.stringify(this.claimDetail));
      this.notes = this.claimDetail.notes;
      this.employeeNotes = this.claimDetail.employeeNotes;
      this.chkConsent = this.claimDetail.chkConsent;
    }
    this.claimAmount = this.convertStringtoNumber(this.claimDetail.claimAmount);
    this.claimId = this.claimDetail.claimId;
    this.status = this.claimDetail.status;
    this.chkConsent = this.claimDetail.chkConsent;
    this.notes = this.claimDetail.notes;
    this.userService.setHRMenu("emp");
    this.loadingService.disableLoading();
  }

  convertStringtoNumber(amount: string): number {
    return Number(amount);
  }

  cancelClaim(): void {
    this.claim = {
      claimId: this.claimId, status: "Cancelled", notes: this.notes, employeeNotes: this.employeeNotes
    }

    this.claimService.updateClaim(this.claim).subscribe(
      (result) => {
        this.systemMessage = "Claim was Cancelled Successfully";
        this.router.navigate(['/employeeViewClaim']);
      },
      (err) => { this.systemMessage = "Fail to Cancel Claim" }
    );
  }

  submitClaim(status: string) {
    this.loadingService.enableLoading();
    this.claimDetail["status"] = status;
    this.claimDetail["employeeNotes"] = this.employeeNotes;
    this.claimService.submitClaim(this.claimDetail).subscribe(
      claim => {
        this.openSnackbar('Claim with attachments has been successfully submitted. Please continue to attach your softcopy of receipts - <' + claim.claimId + '>');
        this.claimService.setClaimInformation(claim);
        this.router.navigate(['/employeeViewClaim']);
      },
      err => {
        this.openAlertSnackbar('There is an error: ' + err.message); console.error('There was an error: ' + err.message);
        this.loadingService.disableLoading();
      }
    );
  }

  openSnackbar(msg: string) {
    this.snackBar.open(msg, "Got it!",
      {
        duration: 3000
      });
  }

  openAlertSnackbar(msg: string) {
    this.snackBar.open(msg, "Dismiss",
      {
        duration: 10000,
        panelClass: ["snack-error"]
      });
  }

  cancel(): void {
    this.utilService.keepValue();
    this.router.navigate(['/employeeViewClaim']);
  }
}
