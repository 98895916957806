import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../loading.service';
import { Claim } from '../claim';
import { ClaimService } from '../claim.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { UserService } from '../loginCore/user.service';


@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.css']
})
export class AttachmentComponent implements OnInit {

  claimDetail: Claim;
  claimAmount: number;

  constructor(
    private loadingService: LoadingService,
    public claimService: ClaimService,
    public snackBar: MatSnackBar,
    public router: Router,
    public userService: UserService,

  ) { }

  ngOnInit() {
    if (this.claimService.getClaimInformation() === undefined) {
      console.log("abc");
      this.claimDetail = JSON.parse(localStorage.getItem('claimObj'));
    } else {
      console.log(this.claimService.getClaimInformation());
      this.claimDetail = this.claimService.getClaimInformation();
      localStorage.setItem('claimObj', JSON.stringify(this.claimDetail));
    }
    this.loadingService.disableLoading();
    this.userService.setHRMenu("emp");
  }


  convertStringtoNumber(amount: string): number {
    return Number(amount);
  }

  submitClaim(status: string) {
    this.loadingService.enableLoading();
    this.claimDetail = JSON.parse(localStorage.getItem('claimObj'));
    this.claimDetail["status"] = status;
	
    this.claimService.submitClaim(this.claimDetail).subscribe(
      claim => {
        this.openSnackbar('Claim with attachments has been successfully submitted. Please continue to attach your softcopy of receipts - <' + claim.claimId + '>');
        this.claimService.setClaimInformation(claim);
        this.router.navigate(['/employeeViewClaim']);
      },
      err => {
        this.openAlertSnackbar('There is an error: ' + err.message); console.error('There was an error: ' + err.message);
        this.loadingService.disableLoading();
      }
    );
  }

  openSnackbar(msg: string) {
    this.snackBar.open(msg, "Got it!",
      {
        duration: 3000
      });
  }

  openAlertSnackbar(msg: string) {
    this.snackBar.open(msg, "Dismiss",
      {
        duration: 10000,
        panelClass: ["snack-error"]
      });
  }

  cancel(){
    this.router.navigate(['/home']);
  }

}
