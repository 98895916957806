<app-navigation></app-navigation>
<div class="container">
    <div class="table-responsive text-nowrap">
        <h2 class="mt-3 solvay">Create New Claim Report</h2>
        <div #reportpdf>
            <table class="table">
                <thead class="black white-text">
                    <tr>
                        <th scope="col">Claim ID</th>
                        <th scope="col">Submission Date</th>
                        <th scope="col">Claim Type</th>
                        <th scope="col">Claim For</th>
                        <th scope="col">Total Claim</th>
                        <th scope="col">Claim Status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let claim of reportedClaims">
                        <td>{{this.claimService.formatClaimID(claim.claimId)}}</td>
                        <td>{{this.claimService.formatDate(claim.submissionDate)}}</td>
                        <td>{{claim.typeOfClaim}}</td>
                        <td>{{claim.dependentName}}</td>
                        <td>{{claim.claimAmount}}</td>
                        <td>{{claim.status}}</td>
                        <td><button type="submit" class="btn redtext mr-2" (click)="removeReport(claim.claimId)">Remove from Report</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <button type="submit" class="btn btn-success mr-2" (click)="download()">Generate Claim Report</button>
    <button type="submit" class="btn btn-danger" (click)="clearReport()">Clear Claim Report</button>
    <br/>
    <br/>
    <h2 class="mt-3 solvay">Available Claims</h2>
    <div class="table-responsive text-nowrap">
        <table class="table" matSort (matSortChange)="sortData($event)">
            <thead class="black white-text">
                <tr>
                    <th mat-sort-header="claimId" scope="col">Claim ID</th>
                    <th mat-sort-header="submissionDate" scope="col">Submission Date</th>
                    <th mat-sort-header="typeOfClaim" scope="col">Claim Type</th>
                    <th mat-sort-header="dependentName" scope="col">Claim For</th>
                    <th mat-sort-header="claimAmount" scope="col">Total Claim</th>
                    <th mat-sort-header="status" scope="col">Claim Status</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let claim of sortedData">
                    <td>{{this.claimService.formatClaimID(claim.claimId)}}</td>
                    <td>{{this.claimService.formatDate(claim.submissionDate)}}</td>
                    <td>{{claim.typeOfClaim}}</td>
                    <td>{{claim.dependentName}}</td>
                    <td>{{this.claimService.formatMoney(claim.claimAmount)}}</td>
                    <td>{{claim.status}}</td>
                    <td><button type="submit" class="btn bluetext mr-2" (click)="addReport(claim.claimId)">Add to Report</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<app-footer></app-footer>
