import { Component } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { AuthService } from './loginCore/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'iFlex-Thailand';

  constructor(public authService: AuthService, private router: Router, private userIdle: UserIdleService) { }

  ngOnInit() {
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => console.log(count));

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      console.log('Time is up!');
      this.authService.doLogout();
      this.router.navigate(['/login'])
      }
    );
  }
}
