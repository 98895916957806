import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Claim } from '../claim';
import { EAPBalance } from '../eapBalance';
import { Router } from '@angular/router';
import { ClaimSearchPipe } from '../claimsearch.pipe';
import { LoadingService } from '../loading.service';
import { Sort } from '@angular/material/sort';
import { UtilService } from '../util.service';

@Component({
  selector: 'app-employee-viewclaim',
  templateUrl: './employee-viewclaim.component.html',
  styleUrls: ['./employee-viewclaim.component.css']
})
export class EmployeeViewclaimComponent implements OnInit {
  claims: Claim[];
  filteredClaims: Claim[];
  claim: Claim;

  eapBalance: EAPBalance;

  claimId: string;
  receiptNo: string;
  typeOfClaim = [
    { id: 1, label: "Flexible" },
    { id: 2, label: "Dental" },
    { id: 3, label: "EAP (Mental Health)" }
  ];
  status = [
    { id: 1, label: 'Pending Attachment'},
    { id: 2, label: 'Submitted'},
    { id: 3, label: 'Rejected'},
    { id: 4, label: 'Cancelled'},
    { id: 5, label: 'Approved'},
    { id: 6, label: 'Pending Payment'},
    { id: 7, label: 'Paid'}
  ];

  quotaYear: string;
  currentYear: string;
  lastYear: string;

  submissionDateFrom: NgbDateStruct;
  submissionDateTo: NgbDateStruct;

  dependentName: string;

  selectedClaimType: string;
  selectedStatus: string;

  disableLoading: number = 0;

  sortedData: Claim[];
  sort: Sort;

  constructor(
    private claimService: ClaimService,
    private router: Router,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private loadingService: LoadingService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.getClaims();
    this.getQuotaYears();
    this.getInitialEAP();
  }

  // get InitialEAPBalance
  getInitialEAP() {
    this.claimService.getInitialEAP(this.quotaYear)
      .subscribe(eapBalance => {
        this.eapBalance = eapBalance;
        console.log(this.eapBalance);
        this.getClaimType();
        this.disableLoading += 1;
        if (this.disableLoading == 2) this.loadingService.disableLoading();
      });
  }

  // get claim Type when page load
  getClaimType() {
    if (this.eapBalance["initialEAP"] == 0) {
      this.typeOfClaim = [
        { id: 1, label: "Flexible" },
        { id: 2, label: "Dental" }
      ];
    }
  }

  getQuotaYears() {
    var quotaYears = this.utilService.getQuotaYears();
    this.currentYear = "" + quotaYears[0];
    this.lastYear = "" + quotaYears[1];
    this.quotaYear = this.currentYear;
  }

  selectedClaimTypeHandler(event: any) {
    // expect to return selected id
    this.selectedClaimType = this.typeOfClaim[event - 1].label;
  }

  selectedStatusHandler(event: any) {
    // expect to return selected id
    this.selectedStatus = this.status[event - 1].label;
  }

  getClaims(): void {
    this.claimService.getMyClaims()
      .subscribe(claims => {
        this.claims = claims;
        this.filteredClaims = claims;
        this.sortedData = this.filteredClaims;
        this.presortData();
        this.disableLoading += 1;
        if(this.disableLoading == 2) this.loadingService.disableLoading();
        if(this.utilService.getRetainValue()){
          var claimFilter = JSON.parse(localStorage.getItem("claimFilter"));
          if(claimFilter != null){
            Object.keys(claimFilter).forEach((key) => {
              if (key == 'claimId') this.claimId = claimFilter.claimId;
              if (key == 'status') this.selectedStatus = claimFilter.status;
              if (key == 'receiptNo') this.receiptNo = claimFilter.receiptNo;
              if (key == 'dateFrom') this.submissionDateFrom = this.ngbDateParserFormatter.parse(claimFilter.dateFrom);
              if (key == 'dateTo') this.submissionDateTo = this.ngbDateParserFormatter.parse(claimFilter.dateTo);
            });
            this.search();
          }
        }else{
          localStorage.clear();
        }
      });
  }

  detail(claimId: string) {
    for (var i = 0; i < this.claims.length; i++) {
      if (this.claims[i].claimId == claimId) {
        this.claimService.setClaimInformation(this.claims[i]);
      }
    }
    this.router.navigate(['/employeeClaimDetail']);
  }

  reload(): void {
    location.reload();
  }

  search(): void {
    let resultClaim = new ClaimSearchPipe();
    var claimFilter = this.claimService.claimFilter;
    claimFilter.claimId = this.claimId;
    claimFilter.claimType = this.selectedClaimType;
    claimFilter.receiptNo = this.receiptNo;
    claimFilter.status = this.selectedStatus;
    claimFilter.dependentName = this.dependentName;
    claimFilter.dateFrom = this.ngbDateParserFormatter.format(this.submissionDateFrom);
    claimFilter.dateTo = this.ngbDateParserFormatter.format(this.submissionDateTo);

    localStorage.setItem('claimFilter', JSON.stringify(claimFilter));

    let dateFrom = new Date(this.ngbDateParserFormatter.format(this.submissionDateFrom));
    let dateTo = new Date(this.ngbDateParserFormatter.format(this.submissionDateTo));

    this.filteredClaims = resultClaim.transform(this.claims, this.claimService.unformatClaimID(this.claimId), this.selectedClaimType, dateFrom, dateTo, this.selectedStatus, "", this.receiptNo, this.dependentName);
    this.sortedData = this.filteredClaims;
    if(this.sort != undefined)
      this.sortData(this.sort);
  }

  sortData(sort: Sort) {
    this.sort = sort;
    const data = this.filteredClaims;
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'claimId': return compare(a.claimId, b.claimId, isAsc);
        case 'typeOfClaim': return compare(a.typeOfClaim, b.typeOfClaim, isAsc);
        case 'dependentName': return compare(a.dependentName, b.dependentName, isAsc);
        case 'claimAmount': return compare(a.claimAmount, b.claimAmount, isAsc);
        case 'status': return compare(a.status, b.status, isAsc);
        case 'submissionDate': return compare(a.submissionDate, b.submissionDate, isAsc);
        case 'categoryOfClaim': return compare(a.categoryOfClaim, b.categoryOfClaim , isAsc);
        case 'receiptNo': return compare(a.receiptNo , b.receiptNo , isAsc);

        default: return 0;
      }
    });
    this.sortedData = data;
  }

  presortData(){
    const data = this.filteredClaims;

    data.sort((a, b) => {
      return compare(a.status, b.status, false);
    });

    this.sortedData = data;
    this.filteredClaims = data;

    const data2 = this.filteredClaims;

    data2.sort((a, b) => {
      return compare(a.submissionDate, b.submissionDate, false);
    });
    this.sortedData = data2;
    this.filteredClaims = data2;
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
