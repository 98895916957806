import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AuthGuard } from './loginCore/auth.guard';
import { AuthService } from './loginCore/auth.service';
import { UserService } from './loginCore/user.service';
import { environment } from '../environments/environment';
import { ClaimService } from './claim.service';
import { AccessService } from './access.service';
import { AppRoutingModule } from './app-routing.module';
import { NavigationComponent } from './navigation/navigation.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SubmitClaimComponent } from './submit-claim/submit-claim.component';
import { EmployeeClaimreportComponent } from './employee-claimreport/employee-claimreport.component';
import { HrViewclaimComponent } from './hr-viewclaim/hr-viewclaim.component';
import { HrClaimdetailComponent } from './hr-claimdetail/hr-claimdetail.component';
import { TokenInterceptor } from './loginCore/token.interceptor';
import { ClaimSearchPipe } from './claimsearch.pipe';
import { EmployeeViewclaimComponent } from './employee-viewclaim/employee-viewclaim.component';
import { EmployeeClaimdetailComponent } from './employee-claimdetail/employee-claimdetail.component';
import { NgxLoadingModule } from 'ngx-loading';
import { LoadingService } from './loading.service';
import { HrSummaryreportComponent } from './hr-summaryreport/hr-summaryreport.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import {  MatSortModule } from '@angular/material/sort';
import { UtilService } from './util.service';
import { FooterComponent } from './footer/footer.component';
import { EmployeeViewquotaComponent } from './employee-viewquota/employee-viewquota.component';
import { UserIdleModule } from 'angular-user-idle';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { AttachmentComponent } from './attachment/attachment.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SubmitClaimComponent,
    EmployeeClaimreportComponent,
    HrViewclaimComponent,
    HrClaimdetailComponent,
    ClaimSearchPipe,
    EmployeeViewclaimComponent,
    EmployeeClaimdetailComponent,
    HrSummaryreportComponent,
    FooterComponent,
    EmployeeViewquotaComponent,
    FileUploadComponent,
    AttachmentComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatSortModule,
    UserIdleModule.forRoot({idle: 3600, timeout: 10, ping: 0})
  ],
  providers: [ClaimService, AccessService, AuthService, UserService, LoadingService, UtilService, AuthGuard,{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
