import { Component, OnInit } from '@angular/core';
import { AuthService } from '../loginCore/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isHR: boolean;

  constructor(public authService: AuthService, private router: Router) { }

  ngOnInit() {
  }

  tryGoogleLogin(){
    this.authService.doGoogleLogin()
    .then(res => {
      this.router.navigate(['/home']);
    }).then();
  }

}
