// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    // Test Firebase
    apiKey: "AIzaSyCHjZHr9v9vyG5gQMjIIYWmIMx_lk-Nbic",
    authDomain: "solvay-iflexi-thailand-test.firebaseapp.com",
    projectId: "solvay-iflexi-thailand-test",
    storageBucket: "solvay-iflexi-thailand-test.appspot.com",
    messagingSenderId: "192211383928",
    apiURL: "https://iflex-thailand-api-52.solvay.com/api/",
    nonApiURL: "https://iflex-thailand-api-52.solvay.com/"

    /* Benard local firebase
    apiKey: "AIzaSyDw-9H6yF861Q5K5OwctcHg_CpJzGmJ8fM",
    authDomain: "pacific-pride-207206.firebaseapp.com",
    databaseURL: "https://pacific-pride-207206.firebaseio.com",
    projectId: "pacific-pride-207206",
    storageBucket: "pacific-pride-207206.appspot.com",
    messagingSenderId: "965003891223"
    */
  },
  production: false
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
