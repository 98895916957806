import { Attachments } from './attachments';

export class Claim {
    attachments: Attachments[];
    claimId: string;
    keyString: string;
    categoryOfClaim: string;
    claimAmount: string;
    employeeId: string;
    personId: string;
    receiptNo: string;
    receiptDate: string;
    status: string;
    submissionDate: string;
    typeOfClaim: string;
    updatedOn: string;
    notes: string;
    approvedAmount: string;
    employeeName: string;
    dependentName: string;
    employeeNotes: string;
    chkConsent: string;
  }
  