import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../loginCore/user.service';
import { AuthService } from '../loginCore/auth.service';
import { LoadingService } from '../loading.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  userName: string;
  constructor(
    private router: Router,
    public userService: UserService,
    public authService: AuthService,
    public loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.getUserName();
    this.userService.getHRRole();
  }

  //function to get username from user service
  getUserName(): void{
    this.userService.getCurrentUser().then(user => this.userName = user.displayName);
  }

  //function to route to home page and reload
  home(){
    this.router.navigate(['/home']);
  }

  isHRRole() {
    return this.userService.isHR;
  }

  viewClaim(){
    this.router.navigate(['/hrViewClaim']);
  }

  viewSummaryReport(){
    this.router.navigate(['/hrSummaryReport']);
  }

  //function to log out
  logout(){
    this.authService.doLogout()
    .then((res) => {
      this.router.navigate(['/login']);
    }, (error) => {
      console.log("Logout error", error);
    });
  }


}
