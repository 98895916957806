<app-navigation></app-navigation>
<br>
<div class="container">
    <div style="margin-left:5%;">
        <div *ngIf="showFlexi">
            <div class="container">
                <label class="solvay"><strong><label class="{{ isQuotaYearCurrentYear() ? 'current-quota-year' : 'past-quota-year' }}">[{{this.quotaYear}}]</label> Flexi Amount</strong>
                </label>
                <div class="row">
                    <div class="solvay-label col-sm-3"><label class="label-quota">Available Flexi Amount</label></div>
                    <div class="col-sm-3"><label class="label-quota">{{ flexiSummary?.availableFlexi | number : '1.2-2' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="solvay-label col-sm-3"><label class="label-quota">Paid Flexi Amount</label></div>
                    <div class="col-sm-3"><label class="label-quota">{{ flexiSummary?.paidFlexi | number : '1.2-2' }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="solvay-label col-sm-3"><label class="label-quota">In Process Flexi Amount</label></div>
                    <div class="col-sm-3"><label class="label-quota">{{ flexiSummary?.pendingFlexi | number : '1.2-2' }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="showDental">
            <div class="container">
                <label class="solvay"><strong><label class="{{ isQuotaYearCurrentYear() ? 'current-quota-year' : 'past-quota-year' }}">[{{this.quotaYear}}]</label> Dental Amount for {{ selectedPersonName }}</strong>
                </label>
                <div class="row">
                    <div class="solvay-label col-sm-3"><label class="label-quota">Available Dental Amount</label></div>
                    <div class="col-sm-3"><label class="label-quota">{{ dentalSummary?.availableDental | number : '1.2-2' }}</label></div>
                </div>
                <div class="row">
                    <div class="solvay-label col-sm-3"><label class="label-quota">Paid Dental Amount</label></div>
                    <div class="col-sm-3"><label class="label-quota">{{ dentalSummary?.paidDental | number : '1.2-2' }}</label></div>
                </div>
                <div class="row">
                    <div class="solvay-label col-sm-3"><label class="label-quota">In Process Dental Amount</label></div>
                    <div class="col-sm-3"><label class="label-quota">{{ dentalSummary?.pendingDental | number : '1.2-2' }}</label></div>
                </div>
            </div>
        </div>

        <div *ngIf="showEAP">
            <div class="container">
                <label class="solvay"><strong><label class="{{ isQuotaYearCurrentYear() ? 'current-quota-year' : 'past-quota-year' }}">[{{this.quotaYear}}]</label> EAP (Mental Health) Amount for {{ selectedPersonName }}</strong>
                </label>
                <div class="row">
                    <div class="solvay-label col-sm-4"><label class="label-quota">Available EAP (Mental Health) Amount</label></div>
                    <div class="col-sm-3"><label class="label-quota">{{ eapSummary?.availableEAP | number : '1.2-2' }}</label></div>
                </div>
                <div class="row">
                    <div class="solvay-label col-sm-4"><label class="label-quota">Paid EAP (Mental Health) Amount</label></div>
                    <div class="col-sm-3"><label class="label-quota">{{ eapSummary?.paidEAP | number : '1.2-2' }}</label></div>
                </div>
                <div class="row">
                    <div class="solvay-label col-sm-4"><label class="label-quota">In Process EAP (Mental Health) Amount</label></div>
                    <div class="col-sm-3"><label class="label-quota">{{ eapSummary?.pendingEAP | number : '1.2-2' }}</label></div>
                </div>
            </div>
        </div>

        <br>
        <form (ngSubmit)="submitClaim('Submitted')" #claimForm="ngForm">

            <h2 class="mt-3 solvay">Submit a Claim</h2><br>
            <div class="row">
                <div class="col-md-2">
                    <label class="solvay">Claim Year</label></div>
                <div class="col-md-6">
                    <select class="custom-select form-control solvay dropdown" name="quotaYear" required [(ngModel)]="quotaYear" (change)="selectedQuotaYearHandler(quotaYear);getDentalSummary(claim['personId']);getFlexiPoint();">
            <option [ngValue]="this.currentYear">{{this.currentYear}}</option>
            <option [ngValue]="this.lastYear" *ngIf="isBackDateClaim">{{this.lastYear}}</option>
          </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <label class="solvay">Claim for</label></div>
                <div class="col-md-6">
                    <select class="custom-select form-control solvay dropdown" name="claimFor" required [(ngModel)]="claim['personId']" (change)="selectedFamilyMembersHandler(claim['personId']);getDentalSummary(claim['personId']);">
            <!--<option [ngValue]="undefined" hidden>Claim for aaaa</option>-->
            <option *ngFor="let member of familyMembers" [ngValue]="member.personId">
              {{ member.firstName + " " + member.lastName +  " | " + member.relationship }}
            </option>
          </select>
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-2">
                    <label class="solvay">Type of Claim</label>
                </div>
                <div class="col-md-6">
                    <select class="custom-select form-control solvay dropdown" name="type" required [(ngModel)]="claim['typeOfClaim']" (ngModelChange)="filterClaimCategories(claim['typeOfClaim']);" (change)="selectedClaimTypeHandler(claim['typeOfClaim'])">
            <!--<option [ngValue]="undefined" hidden>Type of claim</option>-->
            <option *ngFor="let i of claimType" [ngValue]="i.label">{{ i.label }}</option>
          </select>
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-2">
                    <label class="solvay">Category of Claim</label></div>
                <div class="col-md-6">
                    <select class="custom-select form-control solvay dropdown" id="categoryOfClaim" name="categoryOfClaim" required [(ngModel)]="claim['categoryOfClaim']" #categoryOfClaim="ngModel">
            <!--<option [ngValue]="undefined" hidden>Category of claim</option>-->
            <option value="" disabled selected></option>
            <option *ngFor="let filteredCat of filteredCategories" [ngValue]="filteredCat.label">{{ filteredCat.label }}
            </option>
          </select>
                    <div [hidden]="categoryOfClaim.valid || categoryOfClaim.pristine" class="alert alert-danger">
                        Category of Claim is required
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <label class="solvay">Receipt No.</label>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control solvay inputtext" id="receiptNo" name="receiptNo" required [(ngModel)]="claim['receiptNo']" #receiptNo="ngModel" />
                    <div [hidden]="receiptNo.valid || receiptNo.pristine" class="alert alert-danger">
                        Receipt No. is required
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <label class="solvay">Receipt Date</label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="input-group">
                            &nbsp;<input type="text" class="form-control solvay" readonly="true" placeholder="yyyy-mm-dd" name="receiptDate" required [(ngModel)]="receiptDateBoxObject" #receiptDateBox="ngModel" ngbDatepicker #d="ngbDatepicker" (dateSelect)="onDateSelect($event)">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="d.toggle();" type="button">
                  <img src="assets/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;" />
                </button>
                            </div>
                        </div>
                        <div [hidden]="receiptDateBox.valid || receiptDateBox.pristine" class="alert alert-danger">
                            Receipt Date is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <label class="solvay">Claim Amount</label>
                </div>
                <div class="col-md-6">
                    <input type="number" class="form-control solvay inputtext" id="claimAmount" name="claimAmount" required [(ngModel)]="claim['claimAmount']" #claimAmount="ngModel" />
                    <div [hidden]="claimAmount.valid || claimAmount.pristine" class="alert alert-danger">
                        Claim Amount is required
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <label class="solvay">Employee Notes</label>
                </div>
                <div class="col-md-6">
                    <textarea class="form-control solvay inputtext" name="employeeNotes" id="employeeNotes" cols="33" rows="4" [(ngModel)]="claim['employeeNotes']" #employeeNotes="ngModel"></textarea>
                </div>
            </div>
            <div class="row">
				<div>
					<input type="checkbox" id="chkConsent" name="chkConsent" required [(ngModel)]="claim['chkConsent']" #chkConsent="ngModel"  />
					<span class="solvay"> I consent to the processing of my health information and any other sensitive data as shown in the submitted medical certificate.<br/>In case of submission of my family’s medical certificate, I hereby confirm that I have duly received the consent of the respective person and is fully authorized to submit the medical certificate to Solvay on their behalf.
                    </span> 
				</div>
				<div [hidden]="chkConsent.valid || chkConsent.pristine" class="alert alert-danger">
					Consent is required
				</div>                
            </div>   
            <br/>
            <button type="submit" [disabled]="!claimForm.form.valid" class="btn btn-success" id="submitclaimBtn" (click)="submitClaim('Pending Attachment')">Save & Continue</button><br>
        </form>
    </div>
    <app-footer></app-footer>
