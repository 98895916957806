import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ClaimService } from '../claim.service';
import { UserService } from '../loginCore/user.service';
import { Person } from '../person';
import { FlexiSummary } from '../flexisummary';
import { DentalSummary } from '../dentalsummary';
import { EAPBalance } from '../eapBalance';
import { EAPSummary } from '../eapsummary';
import { LoadingService } from '../loading.service';
import { UtilService } from '../util.service';
import { Claim } from '../claim';
import { Router } from '@angular/router';
import { Datebox } from '../databox';

@Component({
  selector: 'app-submit-claim',
  templateUrl: './submit-claim.component.html',
  styleUrls: ['./submit-claim.component.css']
})
export class SubmitClaimComponent implements OnInit {

  constructor(
    private claimService: ClaimService,
    public userService: UserService,
    public utilService: UtilService,
    private loadingService: LoadingService,
    public snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit() {
    this.getQuotaYears();
    this.getInitialEAP();
    this.getFlexiPoint();
    this.getFamilyMembers();
    this.getEAPBalance();
    this.getUserName();
    this.checkBackdateClaim();
  }

  familyMembers: Person[];

  submitted = false;
  pendingAttachment = false;

  user: Object = new Object;
  //function to get username from user service
  getUserName(): void {
    this.userService.getCurrentUser().then(user => this.user = user);
  }

  flexiSummary: FlexiSummary;
  dentalSummary: DentalSummary;
  eapBalance: EAPBalance;
  eapSummary: EAPSummary;
  systemMessage: string;

  receiptDateBoxObject: Datebox;

  father: string = "Father";
  mother: string = "Mother";
  self: string = "Self";
  spouse: string = "Spouse";
  child: string = "Child";
  flexiType: string = "Flexible";
  dentalType: string = "Dental";
  eapType: string = "EAP (Mental Health)";
  showFlexi: boolean = false;
  showDental: boolean = false;
  showEAP: boolean = false;
  isBackDateClaim: boolean = false;
  closingDate: Date;
  personId: string;
  typeOfClaim:string;
  quotaYear: string;
  currentYear: string;
  lastYear: string;
  categoryOfClaim:any;
  disableLoading: number = 0;

  claimTypeList = [
    { id: 0, label: "" },
    { id: 1, label: "Flexible" },
    { id: 2, label: "Dental" },
    { id: 3, label: "EAP (Mental Health)" }
  ];

  // for re-generate claim type based on relatopship
  claimType = [
    { id: 0, label: "" },
    { id: 1, label: "Flexible" },
    { id: 2, label: "Dental" },
    { id: 3, label: "EAP (Mental Health)" }
  ];

  claimCategories = [
    { id: 1, label: "OPD", type: "Flexible" },
    { id: 2, label: "Dental", type: "Flexible" },
    { id: 3, label: "Optical Care", type: "Flexible" },
    { id: 4, label: "Dental", type: "Dental" },
    { id: 5, label: "EAP (Mental Health)", type: "EAP (Mental Health)" }
  ];

  public filteredCategories = [];

  // get InitialEAPBalance
  getInitialEAP() {
    this.claimService.getInitialEAP(this.quotaYear)
      .subscribe(eapBalance => {
        this.eapBalance = eapBalance;
        this.getClaimType();
        this.disableLoading += 1;
        if (this.disableLoading == 4) this.loadingService.disableLoading();
      });
  }

  // get claim Type when page load
  getClaimType() {
    if (this.eapBalance["initialEAP"] == 0) {
      this.claimType = [
        { id: 0, label: "" },
        { id: 1, label: "Flexible" },
        { id: 2, label: "Dental" },
      ];

      this.claimTypeList = [
        { id: 0, label: "" },
        { id: 1, label: "Flexible" },
        { id: 2, label: "Dental" },
      ];

      this.claimCategories = [
        { id: 1, label: "OPD", type: "Flexible" },
        { id: 2, label: "Dental", type: "Flexible" },
        { id: 3, label: "Optical Care", type: "Flexible" },
        { id: 4, label: "Dental", type: "Dental" },
      ];
    }
  }

  // filter on claimCategories
  filterClaimCategories(type: string) {
    if (this.selectedPersonRelationship == this.father || this.selectedPersonRelationship == this.mother) {
      this.filteredCategories = [
        { id: 1, label: "OPD", type: "Flexible" }
      ];
    } else if (this.selectedPersonRelationship == this.spouse || this.selectedPersonRelationship == this.child) {
      this.filteredCategories = [
        { id: 1, label: "OPD", type: "Flexible" },
        { id: 2, label: "Dental", type: "Flexible" },
        { id: 4, label: "Dental", type: "Dental" }
      ];
    } else {
      this.filteredCategories = this.claimCategories;
    }

    if (type != "") {
      let obj = this.filteredCategories.filter(m => m.type == type);
      this.filteredCategories = obj;
      // reset the value
      this.categoryOfClaim = "";
      // this.claim["categoryOfClaim"] = "";
    } else {
      this.filteredCategories = [];
      this.claim["categoryOfClaim"] = "";
    }

    return this.filteredCategories;
  }

  selectedClaimType: string;
  selectedCategoryType: string;
  selectedFamilyMember: string;
  selectedPersonName: string;
  selectedPersonRelationship: string;

  selectedClaimTypeHandler(event: any) {
    // expect to return selected id
    this.showHideHandler(event);
  }

  showHideHandler(claimType: string) {
    if (claimType == this.dentalType) {
      this.showDental = true;
      this.showFlexi = false;
      this.showEAP = false;
    } else if (claimType == this.flexiType) {
      this.showFlexi = true;
      this.showDental = false;
      this.showEAP = false;
    } else if (claimType == this.eapType) {
      this.showFlexi = false;
      this.showDental = false;
      this.showEAP = true;
    } else {
      this.showFlexi = false;
      this.showDental = false;
      this.showEAP = false;
    }
  }

  selectedFamilyMembersHandler(event: any) {
    // expect to return selected id
    //this.selectedFamilyMembers = this.familyMembers[event-1].personId;
    this.selectedFamilyMember = event;
    this.getSelectedPersonName(event);
  }

  selectedQuotaYearHandler(event: any) {
    this.quotaYear = event;
  }

  getSelectedPersonName(personID: string) {
    for (let member of this.familyMembers) {
      if (member.personId == personID) {
        this.selectedPersonName = member.firstName + " " + member.lastName;
      }
    }
  }

  getSelectedPersonRelationship(personID: string) {
    for (let member of this.familyMembers) {
      if (member.personId == personID) {
        this.selectedPersonRelationship = member.relationship;
        // Re-generate ClaimType list based on relationship
        this.claimTypeController(this.selectedPersonRelationship);
      }
    }
  }

  claimTypeController(relationship: string) {
    if (relationship == this.father || relationship == this.mother) {
      // for Parents
      this.claimType = [
        { id: 1, label: "Flexible" }
      ];
      this.claim["typeOfClaim"] = this.flexiType;
      this.showHideHandler(this.flexiType);
      this.filterClaimCategories(this.flexiType);
    } else if (relationship == this.spouse || relationship == this.child) {
      // for Spouse & Child
      //this.claimType = this.claimTypeList;
      this.claimType = [
        { id: 0, label: "" },
        { id: 1, label: "Flexible" },
        { id: 2, label: "Dental" }
      ];

      // reset value to empty
      this.claim["typeOfClaim"] = "";
      this.showHideHandler("");
      this.filterClaimCategories("");

    } else {
      // for self
      this.claimType = this.claimTypeList;
      // reset value to empty
      this.claim["typeOfClaim"] = "";
      this.showHideHandler("");
      this.filterClaimCategories("");
    }
  }

  getFamilyMembers() {
    this.claimService.getFamilyMembers()
      .subscribe(familyMembers => {
        this.familyMembers = familyMembers;
        this.familyMembers.sort((a, b) => (a.relationship === this.self) ? -1 : (a.firstName > b.firstName) ? 1 : -1);
        this.disableLoading += 1;
        if (this.disableLoading == 4) this.loadingService.disableLoading();
      });
  }

  getQuotaYears() {
    var quotaYears = this.utilService.getQuotaYears();
    this.currentYear = "" + quotaYears[0];
    this.lastYear = "" + quotaYears[1];
    this.quotaYear = this.currentYear;
  }

  getFlexiPoint() {
    this.claimService.getFlexiPoint("" + this.quotaYear)
      .subscribe(flexiSummary => {
        this.flexiSummary = flexiSummary;
        this.disableLoading += 1;
        if (this.disableLoading == 4) this.loadingService.disableLoading();
      });
  }

  getDentalSummary(personID: string) {
    this.getSelectedPersonRelationship(personID);
    if (this.selectedPersonRelationship != this.father || this.selectedPersonRelationship != this.mother) {
      this.claimService.getDentalSummary(personID, this.quotaYear)
        .subscribe(dentalSummary => {
          this.dentalSummary = dentalSummary;
        });
    }
  }

  getEAPBalance() {
    this.claimService.getEAPBalance(this.quotaYear)
      .subscribe(eapSummary => {
        this.eapSummary = eapSummary;
        this.disableLoading += 1;
        if (this.disableLoading == 4) this.loadingService.disableLoading();
      });
  }

  checkBackdateClaim() {
    var thisYear = new Date().getFullYear();
    var date1 = new Date(thisYear + '-01-01');
    var date2 = new Date(thisYear + '-01-31');
    var date3 = new Date();
    if (date3 <= date2 && date3 >= date1) {
      //call the API here, if the the date is lower than the API, then true, else false
      this.claimService.getClosingDate()
        .subscribe(closingDate => {
          if (date3 < new Date(closingDate.closingDate)) {
            this.isBackDateClaim = true;
          }
        });
    }
  }

  claim: Object = new Claim();

  // create Claim Object and ready to submit to backend
  submitClaim(status: string) {

    if (this.submitted) {
      return false;
    }

    // let claim: Claim;
    let date = this.receiptDateBoxObject;

    this.claim["receiptDate"] = date.year + "-" + date.month + "-" + date.day;
    this.claim["claimFor"] = this.claim["personId"];
    this.claim["status"] = status;

    if (!(this.claim["claimAmount"] > 0)) {
      this.openAlertSnackbar("The claim amount has to be more than 0.");
      return false;
    }

    if (this.claim["typeOfClaim"] == this.dentalType) {
      // valid the dental claim is lower or equal to the available amount
      if (this.claim["claimAmount"] > this.dentalSummary.availableDental) {
        this.openAlertSnackbar("The claim amount has exceeded the available amount.");
        return false;
      }
    } else if (this.claim["typeOfClaim"] == this.flexiType) {
      // valid the flexi claim is lower or equal to the available amount
      if (this.claim["claimAmount"] > this.flexiSummary.availableFlexi) {
        this.openAlertSnackbar("The claim amount has exceeded the available amount.");
        return false;
      }
    } else if (this.claim["typeOfClaim"] == this.eapType) {
      // valid the eap claim is lower or equal to the available amount
      if (this.claim["claimAmount"] > this.eapSummary.availableEAP) {
        this.openAlertSnackbar("The claim amount has exceeded the available amount.");
        return false;
      }
    }
    this.submitted = true;
    this.claimService.submitClaim(this.claim).subscribe(
      claim => {
        this.openSnackbar('Claim has been successfully saved. Please continue to attach your softcopy of receipts - <' + claim.claimId + '>');
        this.claimService.setClaimInformation(claim);
        this.router.navigate(['/attachment']);
      },
      err => {
        if ((err.error).includes("Receipt No.") && (err.error).includes("is duplicated")) {
          this.openAlertSnackbar(err.error + ' To submit a new one, please cancel the previous claim with the same Receipt Number first.');
        } else {
          this.openAlertSnackbar('There is an error: ' + err.message); console.error('There was an error: ' + err.message);
        }
        this.submitted = false;
      }
    );
  }

  openSnackbar(msg: string) {
    this.snackBar.open(msg, "Got it!",
      {
        duration: 3000
      });
  }

  openAlertSnackbar(msg: string) {
    this.snackBar.open(msg, "Dismiss",
      {
        duration: 10000,
        panelClass: ["snack-error"]
      });
  }

  onDateSelect(event: any) {
    console.log(event);
  }

  isQuotaYearCurrentYear() {
    return (this.quotaYear == this.currentYear);
  }

}
