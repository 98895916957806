import { Injectable } from '@angular/core';
import { Observable, ObjectUnsubscribedError } from 'rxjs';
// import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as firebase from 'firebase/app';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserService {

  private hrURL = environment.firebase.apiURL + 'hr/v1';
  public isHR: boolean;
  public hrMenu: string;

  constructor(public afAuth: AngularFireAuth, private http: HttpClient) {
  }

  getCurrentUser(){
    return new Promise<any>((resolve, reject) => {
      this.afAuth.onAuthStateChanged(user => {
        if (user) {
          resolve(user);
          return user;
        } else {
          reject('No user logged in');
        }
      })
    })
  }

  getHRRoleAPI(): Observable<any>{
    const url = `${this.hrURL}/ishr`;
    return this.http.get<any>(url);
  }

  getHRRole(): void{
    this.getHRRoleAPI().subscribe(result => this.isHR = result.isHR);
  }


  setHRMenu(page: string){
    this.hrMenu = page;
  }

  getHRMenu(){
    return this.hrMenu;
  }

  user: Object = new Object;
  //function to get username from user service
  getUserName(): void{
    this.getCurrentUser().then(user => this.user = user);
  }

}
