import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import 'firebase/auth';

@Injectable()
export class AuthService {

  constructor(public afAuth: AngularFireAuth) { }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({ 'hd': 'solvay.com' });
      this.afAuth.signInWithPopup(provider)
        .then(value => {
          var userinfo = value.user;
          console.log('Nice, it worked! Welcome ' + userinfo.displayName);
          resolve(value);
        })
        .catch(err => {
          console.log('Something went wrong:', err.message);
          reject(err);
        });
    });
  }

  doLogout() {
    return new Promise<void>((resolve, reject) => {
      if (firebase.auth().currentUser) {
        this.afAuth.signOut()
        console.log('Logout successfully!');
        resolve();
      }
      else {
        reject();
      }
    });
  }

}
