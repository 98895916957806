import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ClaimService } from '../claim.service';
import { Claim } from '../claim';
import { LoadingService } from '../loading.service';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ClaimSearchPipe } from '../claimsearch.pipe';
import { UtilService } from '../util.service';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-hr-summaryreport',
  templateUrl: './hr-summaryreport.component.html',
  styleUrls: ['./hr-summaryreport.component.css']
})
export class HrSummaryreportComponent implements OnInit {
  claims: Claim[];
  reportedClaims: Claim[];
  formattedReportedClaims: Claim[];
  disableLoading: number = 0;
  filteredClaims: Claim[];

  sortedData: Claim[];
  sort: Sort;

  claimId: string;
  employeeId: string;
  receiptNo: string;
  typeOfClaim = [
    { id: 1, label: "Flexible" },
    { id: 2, label: "Dental" }
  ];
  status = [
    { id: 1, label: 'Approved'},
    { id: 2, label: 'Pending Payment'}
  ];
  submissionDateFrom: NgbDateStruct;
  submissionDateTo: NgbDateStruct;

  dependentName: string;

  selectedClaimType: string;
  selectedStatus: string;

  constructor(
    private claimService: ClaimService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private loadingService: LoadingService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.loadingService.enableLoading();
    this.getClaims();
    this.reportedClaims = [];
  }

  selectedClaimTypeHandler(event: any) {
    // expect to return selected id
    this.selectedClaimType = this.typeOfClaim[event - 1].label;
  }

  selectedStatusHandler(event: any) {
    // expect to return selected id
    this.selectedStatus = this.status[event - 1].label;
  }

  @ViewChild('reportpdf',{static: false}) reportContent: ElementRef;

  getClaims(): void {
    this.claimService.getClaims()
      .subscribe(claims => {
        this.claims = this.getAvailableClaims(claims);
        this.sortedData = this.claims;
        this.presortData();
        this.disableLoading += 1;
        if(this.disableLoading == 1) this.loadingService.disableLoading();
      });
  }

  getAvailableClaims(claims: Claim[]): Claim[] {
    let availableClaims: Claim[] = [];
    for (var i = 0; i < claims.length; i++) {
      if (claims[i].status == "Approved" || claims[i].status == "Pending Payment") {
        availableClaims.push(claims[i]);
      }
    }
    this.filteredClaims = availableClaims;
    return availableClaims;
  }

  addReport(claimId: string): void {
    for (var i = 0; i < this.claims.length; i++) {
      if (claimId == this.claims[i].claimId) {
        this.reportedClaims.push(this.claims[i]);
        this.claims.splice(i, 1);
      }
    }
  }

  removeReport(claimId: string): void {
    for (var i = 0; i < this.reportedClaims.length; i++) {
      if (claimId == this.reportedClaims[i].claimId) {
        this.claims.push(this.reportedClaims[i]);
        this.reportedClaims.splice(i, 1);
      }
    }
  }

  clearReport(): void {
    location.reload();
  }

  search(): void {
    let resultClaim = new ClaimSearchPipe();
    let dateFrom = new Date(this.ngbDateParserFormatter.format(this.submissionDateFrom));
    let dateTo = new Date(this.ngbDateParserFormatter.format(this.submissionDateTo));
    this.filteredClaims = resultClaim.transform(this.claims, this.claimService.unformatClaimID(this.claimId), this.selectedClaimType, dateFrom, dateTo, this.selectedStatus, this.employeeId, this.receiptNo, this.dependentName);
    this.sortedData = this.filteredClaims;
    if(this.sort != undefined)
      this.sortData(this.sort);
  }

  download():void {
    this.formattedReportedClaims = this.reportedClaims;
    for(let i=0; i<this.formattedReportedClaims.length; i++){
      this.formattedReportedClaims[i].submissionDate = this.claimService.formatDateReverse(this.formattedReportedClaims[i].submissionDate);
    }
    var filteredReport = this.formattedReportedClaims.map(({employeeId, approvedAmount, submissionDate, claimId, categoryOfClaim}) => ({employeeId, approvedAmount, submissionDate, claimId, categoryOfClaim}));
    var headerClaim: Claim = new Claim();
    for (const report of filteredReport) {
      console.log("Claim ID:", report.claimId);
      report.claimId=""+report.claimId+"";
      console.log("Claim ID:", report.claimId);
    }
    headerClaim.employeeId = "Personal Number";
    headerClaim.approvedAmount = "Amount";
    headerClaim.submissionDate = "Start Date";
    headerClaim.claimId = "Assignment Number";
    headerClaim.categoryOfClaim = "Descriptions";
    filteredReport.unshift(headerClaim);
    this.utilService.exportAsExcelFile(filteredReport, 'PP9_Report');
    for(let i=0; i<this.formattedReportedClaims.length; i++){
      this.formattedReportedClaims[i].submissionDate = this.claimService.formatDateBack(this.formattedReportedClaims[i].submissionDate);
    }
 }

 sortData(sort: Sort) {
   this.sort = sort;
   const data = this.filteredClaims;
   if (!sort.active || sort.direction === '') {
     this.sortedData = data;
     return;
   }

   data.sort((a, b) => {
     const isAsc = sort.direction === 'asc';
     switch (sort.active) {
       case 'claimId': return compare(a.claimId, b.claimId, isAsc);
       case 'employeeId': return compare(a.employeeId, b.employeeId, isAsc);
       case 'typeOfClaim': return compare(a.typeOfClaim, b.typeOfClaim, isAsc);
       case 'dependentName': return compare(a.dependentName, b.dependentName, isAsc);
       case 'claimAmount': return compare(a.claimAmount, b.claimAmount, isAsc);
       case 'status': return compare(a.status, b.status, isAsc);
       case 'submissionDate': return compare(a.submissionDate, b.submissionDate, isAsc);
       default: return 0;
     }
   });
   this.sortedData = data;
 }

 presortData(){
   const data = this.filteredClaims;

   data.sort((a, b) => {
     return compare(a.status, b.status, false);
   });

   this.sortedData = data;
   this.filteredClaims = data;

   const data2 = this.filteredClaims;

   data2.sort((a, b) => {
     return compare(a.submissionDate, b.submissionDate, false);
   });
   this.sortedData = data2;
   this.filteredClaims = data2;
 }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
